setFocus = {
  init: function(){
    setFocus.checkNavs();

    if(window.location.hash.length > 0){
      setFocus.checkHash(window.location.hash);
    }
    $('.skip-content').on('click', 'a', function(){
      $($(this).attr('href')).attr('tabindex', 0);
    });
  },
  checkHash: function(hash){
    if($(hash).length > 0){
      setFocus.focus(hash);
    }
  },
  checkNavs: function(hash){
    if($('.sidenav').length > 0){
      $('a[href="#nav"]').attr('href', '#sidenav');
    }
  },
  focus: function(el){
    $(el).attr('tabindex', 0);
  }
};
