function googleMap() {

	$('.map-marker').each(function (i, o) {
		$(o).attr('id', 'map-' + i);

		var currentMapId = $(o).attr('id');
		var locArray = JSON.parse(document.getElementById(currentMapId).getAttribute('data-map-locations'));
		var infoWindowContent = document.getElementById(currentMapId).getAttribute('info-window-content');
		var displayinfoWindowOnLoad = true;

		if (infoWindowContent == null) {
			infoWindowContent = "";
			displayinfoWindowOnLoad = false;
		}
		var zoomValue = document.getElementById(currentMapId).getAttribute('data-map-zoom-level');
	    var zoomLevel = parseInt(zoomValue);
		var latitude = parseFloat(locArray[0].lat.replace(',', '.'));
		var longitude = parseFloat(locArray[0].lng.replace(',', '.'));
		var myLatLng = { lat: latitude, lng: longitude };
		var map = new google.maps.Map(document.getElementById(currentMapId),
		{
			center: { lat: latitude, lng: longitude },
			zoom: zoomLevel,
		});

		var contentString =
			'<div class="mapblock-text-area-inner">' +
				'<h2 class="text-center">' +
				locArray[0].title +
				'</h2>' +
				'<div class="icaf-tiny-mce-container">' +
				infoWindowContent +
				'</div>' +
				'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: '<div id="iw_content">' + contentString + '</div>'
		});

		var marker = new google.maps.Marker({
			position: myLatLng,
			map: map,
			title: 'Klicka för info!'
		});

		marker.addListener('click',
			function () {
				infowindow.open(map);
				map.setCenter(marker.getPosition());
			});

		marker.addListener('click', function () {

			//Check if in mobile mode
			if ($("#iw_content").css("width") == "200px") {

				$('.gm-style-iw')
					.parent()
					.css({
						'left': '1.5625rem',
						'top': '3.5625rem'
					});
			} else {

				$('.gm-style-iw')
					.parent()
					.css({
						'left': '6.5625rem',
						'top': '6.5625rem'
					});
			};

			//sets style of infowindow
			$('.gm-style-iw').prev().html('');
			$('.gm-style-iw')
				.css({
					'background-color': 'white',
					'padding': '15px',
					'left': '20px'

				});
		});

		if (displayinfoWindowOnLoad) {
			google.maps.event.addListenerOnce(map,
				'idle',
				function () {
					google.maps.event.trigger(marker, 'click');
				});
		}
	});
}
