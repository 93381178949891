translate = {
	init: function () {
    kitUtils.log('translate init');

		// Google adds top:40px when translation is active so we wish to prevent that extra space.
		if (Cookies.get('googtrans') !== undefined) {
			$('body').css('margin-top', '-40px');
		}

		if ($('.translatebanner').length > 0 && $('.language-picker option').length === 1) {
			var str = '';
			var lang = '/sv/';
			$.each(langs, function (k, v) {
				str = '<option id="' + v + '">' + k + '</option>';
				$('.translatebanner .language-picker').append(str);
				str = '';
			});

			$('.translatebanner .translate').on('click', function (e) {
				e.preventDefault();
				if ($('.language-picker').find(':selected').is(':enabled')) {
					lang += $('.language-picker').find(':selected').attr('id');
					Cookies.set('googtrans', lang, {
						path: '/'
					});
					location.reload();
				}
			});

			// This event turns off the active translation by deleting the cookie.
			$('.translatebanner .turnoff').on('click', function (e) {
				e.preventDefault();
				Cookies.remove('googtrans', {
					path: '/'
				});
				location.reload();
			});

			$(document).on('click', '.toggle-translatebanner', function (e) {
				e.preventDefault();
				$('.translatebanner').slideToggle();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
			});

			$('.translatebanner .cancel').on('click', function (e) {
				e.preventDefault();
				$('.translatebanner').slideToggle();
			});
		}
	}
};

function googleTranslateElementInit() {
	new google.translate.TranslateElement({
		pageLanguage: 'sv',
		layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
		autoDisplay: false
	}, 'google_translate_element');
}
