(function () {
	'use strict';

	var app = angular.module('NewsArchiveApp', ['ngSanitize']);
	app.config([
			'$locationProvider',
			function ($locationProvider) {
				$locationProvider.html5Mode(true);
			}
	]);

	app.controller('NewsArchiveController', ['$scope', '$http', '$window', function ($scope, $http, $window) {

		activate();

		$scope.currentOffset = $window.newsList.pageSize;
		$scope.isRefreshing = false;
		$scope.hasMoreItems = $window.newsList.hasMoreItems;

		$scope.getJSON = function (url, offset, pageSize, categoryId) {

			if ($scope.isRefreshing === true) {
				return;
			}

			$scope.isRefreshing = true;

			if (url == undefined) {
				return;
			}

			if (offset == undefined) {
				offset = $scope.currentOffset;
			}

			var data = {
				offset: offset,
				pageSize: pageSize,
				categoryId: categoryId
			}

			$http({
				method: 'POST',
				url: url,
				data: data
			}).then(function successCallback(response) {

				$scope.totalNumberOfItems = response.data.totalNumberOfItems;
				$('#NewsList').append($(response.data.data));

				//$scope.data += $sce.trustAsHtml("<div iln-match-height>" + response.data.data + "</div>");

				$scope.currentOffset += pageSize;

				$scope.hasMoreItems = response.data.hasMoreItems;

				$scope.isRefreshing = false;

				//console.log("DEBUG: currentOffset: " +
				//    $scope.currentOffset +
				//    ", totalNumberOfItems: " +
				//    $scope.totalNumberOfItems);

			},
					function errorCallback(response) {
						// called asynchronously if an error occurs
						// or server returns response with an error status.
						$scope.isRefreshing = false;
						alert('Ett fel upstod i nyhetslistan.');
					});
		}

		function activate() {

		}
	}]);
})();


var observeDOM = (function () {
	var MutationObserver = window.MutationObserver || window.WebKitMutationObserver,
			eventListenerSupported = window.addEventListener;

	return function (obj, callback) {
		if (obj == null) return false;
		if (MutationObserver) {
			// define a new observer
			var obs = new MutationObserver(function (mutations, observer) {
				if (mutations[0].addedNodes.length || mutations[0].removedNodes.length)
					callback();
			});
			// have the observer observe foo for changes in children
			obs.observe(obj, { childList: true, subtree: true });
		}
		else if (eventListenerSupported) {
			obj.addEventListener('DOMNodeInserted', callback, false);
			obj.addEventListener('DOMNodeRemoved', callback, false);
		}
	};
})();

// Observe a specific DOM element:
$(document).ready(function () {
	observeDOM($('#NewsList')[0],
			function () {
				// Whenever the children of the #NewsList-div changes, recalculate height of all the divs.
				window.setTimeout(function () {
					project.matchHeight();
				}, 300);
			});
});