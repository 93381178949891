var search = {
  searchButton: null,
  searchresultButton: null,
  searchField: null,
  searchresultField: null,
  isShowing: false,
  isSearching: false,
  el: null,
  $el: null,

  initTopSearch: function (container) {
    if ($(container).length > 0) {
      search.el = container;
      search.$el = $(container);

      search.searchButton = $($(container).data('button'));
      search.searchField = $($(container).data('queryfield'));
      search.searchUrl = $(container).data('searchurl');

      search.attachEvents();
    } else {
      return false;
    }
  },

  initSearchResultPage: function (container) {
    if ($(container).length > 0) {
      search.searchresultButton = $($(container).data('button'));
      search.searchresultField = $($(container).data('queryfield'));
      search.attachSearchResultEvents();
    } else {
      return false;
    }
  },

  attachSearchResultEvents: function () {
    search.searchresultButton.on('click', function (e) {
      e.preventDefault();
      search.doSearch(search.searchresultField.val());
    });

    // Enter key event
    search.searchresultField.on('keypress', function (e) {
      if (e.keyCode === 13) {
        search.doSearch(search.searchresultField.val());
      }
    });
  },

  attachEvents: function () {
    search.searchButton.on('click', function (e) {
      e.preventDefault();
      if (search.isShowing) {
        search.doSearch(search.searchField.val());
      } else {
        search.show();
      }
    });

    // Escape key event
    search.searchField.on('keyup', function (e) {
      if (e.keyCode === 27) {
        search.hide();
      }
    });

    // Enter key event
    search.searchField.on('keypress', function (e) {
      if (e.keyCode === 13) {
        search.doSearch(search.searchField.val());
      }
    });

    // "Click outside" event
    $('html').on('click', function (e) {
      if (search.searchButton.length > 0 && search.searchField.length > 0) {
        if (e.target.id !== search.searchButton.attr('id') && e.target.id !== search.searchField.attr('id')) {
          search.hide();
        }
      }
    });

  },

  doSearch: function(query) {
    window.location.href = search.searchUrl + ('?q='+query);
  },

  hide: function () {
    search.$el.removeClass('desktopSearchShowing');
    search.searchField.val('');
    search.isShowing = false;
  },

  show: function () {
    search.$el.addClass('desktopSearchShowing');
    search.searchField.focus();
    search.isShowing = true;
  }
};
