////var cookiebar = {
////	cookieBlock: $('.cookie-bar'),
////	cookieName: 'cookiepolicy',

////	init: function () {
////		kitUtils.log('cookiebar init');
////		if (typeof Cookies.get(cookiebar.cookieName) === 'undefined') {
////			cookiebar.cookieBlock.removeClass('is-hidden');
////			cookiebar.cookiebarClose();
////		} else {
////			cookiebar.cookieBlock.addClass('is-hidden');
////		}
////	},

////	cookiebarClose: function () {
////		cookiebar.cookieBlock.find('.cookieclose').on('click', function () {
////			Cookies.set(cookiebar.cookieName, 'accepted', { expires: parseInt(this.getAttribute('data-expires'), 10)});
////			cookiebar.cookieBlock.addClass('is-hidden');
////		});
////	}
////};

(function () {
	if ($('.cookie-consent_content') !== undefined) {
		$(document).on('ready', function () {
			if ($('[data-redirecturl]') !== undefined) {
				$('[data-redirecturl]').each(function (index, elm) {
					$(elm).val(location.href);
				});
			}

			$('.checkbox_checkboxOuter').each(function (index, elm) {
				var currentInput = $(elm).children('input');
				if (currentInput.attr('checked') === 'checked') {
					$(elm)
						.children('.checkbox_checkbox')
						.first()
						.children('.checkbox_checkbox-icon')
						.css('display', 'block');
				}
			});
		});

		$(document).on('click', '[data-settings]', function () {
			$('.accept-cookies_wrapper_default').css('display', 'none');
			if ($('.accept-cookies_wrapper_settings').css('display') === 'none') {
				$('.accept-cookies_wrapper_settings').css('display', 'grid');
			} else {
				$('.accept-cookies_wrapper_settings').css('display', 'none');
				$('.cookie-consent_darkbg').css('display', 'none');
			}
		});

		$(document).on('click', '.checkbox_checkbox', function () {
			console.log('sadasd');
			var id = event.target.id.split('_')[2];
			var elementNameIcon = '#checkbox_checkbox-icon_' + id;
			var elementNameInput = '#checkbox_focusInput_' + id;

			if ($(elementNameIcon).css('display') === 'none') {
				$(elementNameIcon).css('display', 'block');
				$(elementNameInput).attr('checked', 'checked');
			} else {
				$(elementNameIcon).css('display', 'none');
				$(elementNameInput).removeAttr('checked');
			}
		});

		$(document).on('click', '[data-cookie-button]', function (event) {
			event.preventDefault();
			var form = $(this).parents('form');
			var acceptAllCookies = $(this).data('cookie-button-all');

			//acceptera alla cookies
			if (acceptAllCookies !== undefined) {
				var triggerGA = $(this).data('trigger-ga');
				if (triggerGA === 'True') {
					// eslint-disable-next-line no-undef
					dataLayer.push({
						event: 'consentSettingSave',
						necessaryConsent: 'true',
						analyticsConsent: 'true'
					});
					document.cookie = '_gtm_referrer=' + document.referrer;
				}
			} else {
				var checked = form.find('input[type="checkbox"]');
				if (checked.length > 0) {
					checked.each(function () {
						if ($(this).data('trigger-ga') === 'True') {
							var isAccepted = $(this).is(':checked');
							// eslint-disable-next-line no-undef
							dataLayer.push({
								event: 'consentSettingSave',
								necessaryConsent: 'true',
								analyticsConsent: isAccepted
							});
							if (isAccepted) {
								document.cookie = '_gtm_referrer=' + document.referrer;
							}
							return;
						}
					});
				}
			}
			form.submit();
		});
	}
})();
