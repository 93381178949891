langs = {
  'Afrikaans': 'af',
  'Albanska': 'sq',
  'Arabiska': 'ar',
  'Azerbajdzjanska': 'az',
  'Baskiska': 'eu',
  'Bengali': 'bn',
  'Bosniska': 'bs',
  'Cebuano': 'ceb',
  'Vitryska': 'be',
  'Bulgariska': 'bg',
  'Burmesiska': 'my',
  'Katalanska': 'ca',
  'Förenklad kinesiska': 'zh-CN',
  'Traditionell kinesiska': 'zh-TW',
  'Kroatiska': 'hr',
  'Tjeckiska': 'cs',
  'Danska': 'da',
  'Chichewa': 'ny',
  'Nederländska': 'nl',
  'Engelska': 'en',
  'Esperanto': 'eo',
  'Estniska': 'et',
  'Filippinska': 'tl',
  'Finska': 'fi',
  'Franska': 'fr',
  'Galiciska': 'gl',
  'Georgiansk': 'ka',
  'Hausa': 'ha',
  'Tyska': 'de',
  'Grekiska': 'el',
  'Gujarati': 'gu',
  'Haitisk kreol': 'ht',
  'Hebreiska': 'iw',
  'Hindi': 'hi',
  'Hmong': 'hmn',
  'Igbo': 'ig',
  'Ungerska': 'hu',
  'Isländska': 'is',
  'Indonesiska': 'id',
  'Sundanesiska': 'su',
  'Nepali': 'ne',
  'Irländska': 'ga',
  'Italienska': 'it',
  'Japanska': 'ja',
  'Javanesiska': 'jv',
  'Kazakiska': 'kk',
  'Kannada': 'kn',
  'Khmer': 'km',
  'Koreanska': 'ko',
  'Latin': 'la',
  'Lettiska': 'lv',
  'Litauiska': 'lt',
  'Laotiska': 'lo',
  'Makedonska': 'mk',
  'Malayiska': 'ms',
  'Malayalam': 'ml',
  'Malagassiska': 'mg',
  'Maltesiska': 'mt',
  'Maori': 'mi',
  'Marathi': 'mr',
  'Mongoliska': 'mn',
  'Norska': 'no',
  'Persiska': 'fa',
  'Polska': 'pl',
  'Portugisisk': 'pt',
  'Punjabi': 'pa',
  'Rumänska': 'ro',
  'Ryska': 'ru',
  'Serbiska': 'sr',
  'Sesotho': 'st',
  'Somaliska': 'so',
  'Singalesiska': 'si',
  'Slovakiska': 'sk',
  'Slovenska': 'sl',
  'Spanska': 'es',
  'Swahili': 'sw',
  'Svenska': 'sv',
  'Tamil': 'ta',
  'Tadzjikista': 'tj',
  'Telugu': 'te',
  'Thailändska': 'th',
  'Turkiska': 'tr',
  'Ukrainska ': 'uk',
  'Urdu': 'ur',
  'Uzbekiska': 'uz',
  'Vietnamesiska': 'vi',
  'Welsh': 'cy',
  'Jiddisch': 'yi',
  'Yoruba': 'yo',
  'Zulu': 'zu',
  'Walesiska': 'cy'
};