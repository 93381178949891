var project = {
	init: function () {
		kitUtils.init();
		kitUtils.detectBrowserVendor();
		kitUtils.detectIEVersion();
		kitUtils.detectOS();

		project.BetterXForms();

		project.categoryChangeActiveItem();
		project.randomizeBackgroundColor();
		project.matchHeight();
		project.imagebanner();
		project.socialjs();

		project.initRoyalslider();
		search.initTopSearch('#desktop_search');
		search.initSearchResultPage('#desktop_searchresult');

		setFocus.init();
	},

	categoryChangeActiveItem: function () {
		$(".category .category_item").click(function () {
			$(".category_item").removeClass('active');
			$(this).addClass('active');
		});
	},

	randomizeBackgroundColor: function () {
		var colors = ['#701752', '#89AD3A'];
		var random_color = colors[Math.floor(Math.random() * colors.length)];
		$('.page-newspage .news-tag').css('background-color', random_color);
	},

	matchHeight: function () {
		$('.js-match-height').matchHeight({
			byRow: true,
			property: 'height',
			target: null,
			remove: false
		});

		$('.js-match-height-openinghours').matchHeight({
			byRow: true,
			property: 'height',
			target: null,
			remove: false
		});

		$('.js-match-height-news').matchHeight({
			byRow: true,
			property: 'height',
			target: null,
			remove: false
		});
	},

	isEpiserverEditMode: function () {
		if ($('body').hasClass('episerver')) {
			return true;
		} else {
			return false;
		}
	},

	socialjs: function () {
		if ($('.socialjs').length > 0) {
			$('.socialjs').socialjs();
		}
	},

	imagebanner: function () {
		if ($('.block-imagebanner a p').length > 0) {
			$('.block-imagebanner a p').fitText(0.7, { minFontSize: '16px', maxFontSize: '50px' });
		}
	},

	BetterXForms: function () {
		$('.xform').BetterXforms({
			bsv: 3,
			iCheck: {
				enabled: true,
				labelHover: true,
				checkboxClass: 'icheckbox_square-blue',
				radioClass: 'iradio_square-blue',
				cursor: true
			}
		});
	},

	initRoyalslider: function () {
		kitUtils.log('slider: isEpiserverEditMode: ' + this.isEpiserverEditMode());
		if (!this.isEpiserverEditMode() && $('.royalSlider').length > 0) {
			var imageScaling = ($('.mobile-navigation:visible').length > 0 ? 'fit-if-smaller' : 'fill');
			$('.royalSlider').royalSlider({
				arrowsNav: true,
				autoPlay: {
					enabled: true,
					delay: 5000
				},
				loop: true,
				keyboardNavEnabled: true,
				controlsInside: false,
				imageScaleMode: imageScaling,
				arrowsNavAutoHide: false,
				sliderDrag: false,
				controlNavigation: 'bullets',
				thumbsFitInViewport: false,
				navigateByClick: true,
				startSlideId: 0,
				transitionType: 'move',
				globalCaption: false,
				deeplinking: {
					enabled: true,
					change: false
				},
				/* size of all images http://help.dimsemenov.com/kb/royalslider-jquery-plugin-faq/adding-width-and-height-properties-to-images */
				imgWidth: null,
				imgHeight: null
			});

			var slider = $(".royalSlider").data('royalSlider');
			slider.ev.on('rsBeforeAnimStart', function () {
				$('.slidein').removeClass('show');
			});

			slider.ev.on('rsAfterSlideChange', function () {
				if ($('.rsSlide').eq(slider.currSlideId).find('.slidein').length) {
					$('.slidein').addClass('show');
				}
			});
		}
	}
};

jQuery(document).ready(function ($) {
	project.init();
	googleMap();
});

document.addEventListener("DOMContentLoaded", function (event) {
	flyPanels.init({
		treeMenu: {
			init: true
		},
		search: {
			init: true,
			saveQueryCookie: true
		}
	});
});
