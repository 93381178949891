(function () {
    'use strict';

    var app = angular.module('StoreOverviewApp', []);

    app.config(['$locationProvider',
        function ($locationProvider) {
            $locationProvider.html5Mode(true);
        }
    ]);

    app.controller('StoreOverviewController', ['$scope', '$window', function ($scope, $window) {
        $scope.selectedCategory = -1;
        $scope.allStoreCategories = $window.storeOverview.allStoreCategories;

        $scope.isStoreOfSelectedCategory = function (storeCategories) {
            if (storeCategories.indexOf($scope.selectedCategory) !== -1) {
                return true;
            }
            return false;
        }
    }]);
})();